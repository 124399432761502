#cnil_banner_consent {
  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  background-color: rgba(#333333, 0.9);
  font-family: "Supria Sans", sans-serif;
  transition: all 0.3s ease-in;
  font-size: 1.3em;
  padding: 0 0 0 0;
  z-index: 1000;
  max-height: 90vh;
  overflow: auto;

  @include RWD(mobile) {
    width: 100vw;
    height: max-content;
    bottom: 50%;
    transform: translate(-50%, 50%);
  }

  p {
    width: auto;
    display: inherit;
    margin: 0 1em;
    color: #fff;
    font-size: 15px;
    line-height: 18px;
  }

  .banner-title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
    padding: 25px 15px 0 15px;
    &__icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      vertical-align: bottom;
    }
  }

  a {
    background-color: transparent;
    text-transform: none;
    text-decoration: underline;
    padding: 0;
    text-decoration-color: #187860;
    text-decoration-thickness: 0.3rem;
    text-underline-offset: 5px;
    color: #fff;
  }

  .modalCookies {
    &__close {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 12' style='enable-background:new 0 0 12 12;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23fff;%7D%0A%3C/style%3E%3Cpath class='st0' d='M6.7,6l5.2-5.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L6,5.3L0.8,0.1C0.6,0,0.3,0,0.1,0.1 C0,0.3,0,0.6,0.1,0.8L5.3,6l-5.2,5.2c-0.2,0.2-0.2,0.5,0,0.7C0.2,11.9,0.4,12,0.5,12c0.1,0,0.2,0,0.3-0.1L6,6.7l5.2,5.2 c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L6.7,6z'/%3E%3C/svg%3E%0A");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 25px;
      opacity: 1;
      height: 25px;
      position: absolute;
      right: 20px;
      top: 10px;
      right: 10px;
      transform: translate(0, 0);
    }

    &__btns {
      display: flex;
      justify-content: center;
      margin: 25px 0;
      padding: 0 15px;

      .cookie-btn {
        padding: 10px 20px;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        transition: all 0.4s;
        &:hover {
          opacity: 0.9;
        }

        @media screen and (max-width: 575px) {
          width: 72%;
        }

        &:last-child {
          margin-left: 15px;
          @media screen and (max-width: 575px) {
            margin-left: 0;
            margin-top: 15px;
          }
        }
        &:nth-child(1) {
          padding-right: 20px;
          display: flex;
          align-items: center;
          margin-right: 15px;
          @include RWD(mobile) {
            margin-right: 0;
            margin-bottom: 15px;
            justify-content: center;
          }
          &:after {
            content: "";
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='m8.091 7.136 5.091 4.728-5.091 4.727' fill='none' /%3E%3C/svg%3E");
            background-size: 20px;
            margin-left: 5px;
            background-repeat: no-repeat;
            background-position: center center;
            transform: rotate(90deg);
          }
        }
        &:nth-child(2) {
          background-color: #f5c6cb;
          color: #721c24;
          border: 1px solid #721c24;
        }
        &:nth-child(3) {
          background-color: #d4edda;
          border: 1px solid #155724;
          color: #155724;
        }
      }

      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &__all-choices {
      display: none;
      &__input {
        padding: 15px;
        border-top: 1px solid rgba(#000, 0.5);
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__desc {
          position: relative;
          top: -20px;
          display: none;
          width: 100%;
          font-size: 16px;
          margin-top: 5px;
          font-weight: 400;
          color: #fff;
          font-family: "latoregular";
          transition: all 0.4s ease-out;
        }
        .input-desc-open {
          display: block;
          top: 0;
          animation: 0.3s linear animationHeight;
        }

        @keyframes animationHeight {
          0% {
            opacity: 0;
            top: -20px;
            height: 0;
          }

          100% {
            opacity: 100%;
            top: 0;
            height: 100%;
          }
        }

        label {
          width: calc(100% - 50px);
          font-size: 18px;
          font-weight: 500;
          text-transform: uppercase;
          color: #fff;
          padding-left: 65px;
          height: 30px;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          @media screen and (max-width: 992px) {
            font-size: 16px;
            text-transform: none;
            padding-left: 25px;
          }
          &:before {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: all 0.3s ease-in-out;
          }
        }

        input[type="checkbox"] {
          position: relative;
          border: 0;
          background-color: transparent;
          width: 50px;
          height: 26px;
          cursor: pointer;
          margin: 0;
          //CHECKBOX  ETAT INITIAL
          &:before {
            content: "";
            width: 50px;
            height: 26px;
            background-color: #e6e6e6;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 4px;
          }
          &:after {
            content: "";
            width: 20px;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 4px;
            transition: all 0.3s ease;
          }

          //CHECKBOX  ETATT CHECK
          &:checked {
            &:after {
              background-color: #000;
              left: calc(100% - 25px);
            }
          }
        }
        &.input-open {
          label {
            &:before {
              top: 30%;
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }

      &__validate {
        width: 100%;
        box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
        .cookie-btn {
          position: relative;
          color: #fff;
          max-height: 45px;
          margin-right: 15px;
          margin-left: inherit;
          padding-right: 30px;
          background-color: #d4edda;
          border: 1px solid #155724;
          color: #155724;
          text-align: center;
          padding: 10px 20px;
          border-radius: 5px;
          &::after {
            content: "";
            position: absolute;
            background-size: 90%;
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            right: 5px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }

      &--active {
        display: block;
      }
    }
  }
}

.btn__enable-media,
[data-hp-ytb] {
  margin-bottom: 25px;
  padding: 10px 20px;
  border: 1px solid #368b8c;
  color: #fff;
  background-color: #368b8c;
  transition: all 0.3s ease;
  &:hover {
    color: #368b8c;
    background-color: #fff;
  }
}

.cookies-consent__sticky {
  position: fixed;
  bottom: 2.5%;
  right: 2.5%;
  .btn {
    color: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    padding: 10px 20px;
  }
}

.bloc-media {
  &:not(.media-file) {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .iframe_disabled {
    position: relative !important;
  }
  .bloc-media__target {
    display: none;
    &.embed-60 {
      display: block;
    }
  }
  .media-ratio {
    padding-bottom: 0 !important;
    &--active {
      padding-bottom: 57% !important;
    }
  }
}
